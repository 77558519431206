import React from 'react';
import Container from '../components/layout/Container';
import Layout from '../layout/defaultLayout';

const doelstellingen = () => (
  <Layout>
    <Container>
      <h1>Doelstellingen van de club</h1>
      <section>
        <h2>De missie</h2>
        <p>De vereniging Oxaco BBC VZW stelt zich een 3-ledige missie tot doel:</p>
        <ul>
          <li>
            <h3>Sportief:</h3>
            <p>
              Jongeren uit alle leeftijdscategorieën de kans bieden om de basketsport aan te leren
              en te beoefenen; deze jongeren opleiden, stimuleren en motiveren om als volwassen
              spelers voor de seniorploegen van Oxaco BBC aan te treden. Oxaco biedt jongeren een
              van de allerbeste jeugdopleiding in Vlaanderen en de beste in Antwerpen.
            </p>
          </li>
          <li>
            <h3>Pedagogisch:</h3>
            <p>
              De baskettechnische opleiding en competitie organiseren en verzorgen in een geest van
              respect en fairplay. De betrokkenheid van spelers, bestuursleden en ouders zoveel
              mogelijk stimuleren. Een winners mentaliteit ontwikkelen die niet fanatiek maar gezond
              is: basket = vrije tijd = amusement.
            </p>
          </li>
          <li>
            <h3>Sociaal:</h3>
            <p>
              Voor zowel de actieve als de ex-leden van de club, een platform bieden waar sociale en
              extrasportieve contacten gestimuleerd en georganiseerd kunnen worden, op basis van de
              normen en waarden die de club eigen zijn.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <p>
          Zoals u uit de missie kunt opmaken is het onze bedoeling om zelf spelers op te leiden voor
          onze seniorploegen. Wij rekruteren jonge kinderen – die nog geen ervaring hebben met
          basketbal – in samenwerking met een aantal scholen en leren ze basketbal spelen onder de
          deskundige leiding van een team uitgelezen coaches. De jeugdopleiding duurt ongeveer 16
          jaar (van kleuter tot senior).
        </p>
        <br />
        <br />
        <p>
          Daarna (sommigen al vroeger) kunnen de spelers terecht in één van onze drie seniorploegen:
        </p>
        <ul>
          <li>Oxaco A: de beste spelers die de club heeft kunnen opleiden</li>
          <li>Oxaco B: de aankomende jeugd die ervaring wil opdoen bij de seniors</li>
          <li>Oxaco C: een recreatief team voor basketliefhebbers</li>
        </ul>
      </section>
    </Container>
  </Layout>
);

export default doelstellingen;
